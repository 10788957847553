'use strict'

import {getPrefixedDataSet} from '../../libs/@elements/data-set-utils';
import PhotoSwipeLightbox from '../../libs/photoswipe/dist/photoswipe-lightbox.esm.min.js';

const Selectors = {
    base: '.js-pdsLightbox',
    item: '.js-pdsLightbox__item',
};

const pdsLightboxArrowString = `<svg aria-hidden="true" class="pswp__icn" width="10px" height="18px" viewBox="0 0 10 18">
    <g id=".home" transform="translate(-23.000000, -581.000000)">
        <g id=".stage" transform="translate(0.000000, 100.000000)">
            <g id=".controls" transform="translate(24.000000, 482.000000)">
                <path d="M1.10974101,0.176048337 C1.12290853,0.188164906 1.13556695,0.200823326 1.14768352,0.213990848 L7.80308864,7.44666537 C7.9461529,7.60213859 8.01304511,7.80218194 8.00376527,7.99933426 C8.01327127,8.19714096 7.94639497,8.39759835 7.80308864,8.55333463 L1.14768352,15.7860092 C0.90609011,16.0485576 0.497402355,16.0655451 0.234853873,15.8239517 C0.221686351,15.8118351 0.20902793,15.7991767 0.196911362,15.7860092 C-0.071788019,15.4940036 -0.071788019,15.0447755 0.196911362,14.7527699 L6.411,8 L0.196911362,1.24723007 C-0.071788019,0.955224526 -0.071788019,0.505996388 0.196911362,0.213990848 C0.438504773,-0.0485576338 0.847192527,-0.0655450749 1.10974101,0.176048337 Z" id="arrow.left" transform="translate(4.000000, 8.000000) scale(-1, 1) translate(-4.000000, -8.000000) "></path>
            </g>
        </g>
    </g>
</svg>`

export function initInScope($scope, options = {}) {
    const lightbox = new PhotoSwipeLightbox({
        bgOpacity: 1,
        gallery: Selectors.base,
        children: Selectors.item,
        pswpModule: () => import ('../../libs/photoswipe/dist/photoswipe.esm.min.js'),
        counter: false,
        arrowPrevSVG: pdsLightboxArrowString,
        arrowNextSVG: pdsLightboxArrowString,
        paddingFn: (viewportSize, itemData, index) => {
            return {
                // check based on viewport size
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
            };
        }
    });
    lightbox.init();
}
