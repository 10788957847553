import $ from 'jquery';

import 'popper.js';
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/tooltip';

if (!window.HTMLPictureElement) {
    import('picturefill');
}


let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "../libs/@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('../libs/@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/* shared */
import {cachedFetches} from "../../../shared/cached-fetches/cached-fetches";
window.cachedFetches = cachedFetches.init();


/**********************************
 *
 *      Cookies
 *
 * ********************************/

import * as cart from './../scripts/cart';
app.modules.cart = cart.initInScope;

import * as cartDropdown from './scripts/cart-dropdown';
app.cartDropdown = cartDropdown.init;
app.modules.cartDropdown = cartDropdown.initInScope;

import * as addToCart from './scripts/addToCart';
app.addToCart = addToCart.init;
app.modules.addToCart = addToCart.initInScope;

import * as numberSpinner from './../scripts/numberSpinner';
app.modules.numberSpinner = numberSpinner.initInScope;

import * as cookieOverlay from '../../../shared/cookie-overlay/cookieOverlay';
cookieOverlay.register();

import * as youtubeVideos from '../../../shared/youtube-videos/youtubeVideos';
youtubeVideos.register();

import * as cookieInformation from '../../../shared/cookie-overlay/cookieInformation'
cookieInformation.init();
window.initializeCookieElements = cookieInformation.initializeCookieElements

import * as cookieOverlaysInLightGallery from '../../../shared/cookie-overlay/cookieOverlaysInLightGallery';
window.initializeCookieOverlaysInLightGallery = cookieOverlaysInLightGallery.initializeCookieOverlaysInLightGallery

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

//uncomment if shopfunctionality is enabled
import {unhideByLoginState} from '../../../shared/unhide-by-login-state/unhide-by-login-state'
unhideByLoginState();

import * as ajaxLogin from './../scripts/ajaxLogin';
app.modules.ajaxLogin = ajaxLogin.initInScope;

import * as jsVideo from './../scripts/jsVideo';
app.modules.jsVideo = jsVideo.initInScope;

import * as ejb from '../libs/@elements/elements-job-bundle';
app.modules.ejbAjaxForm = ejb.ajaxForm.initInScope;
app.modules.ejbDateInput = ejb.dateInput.initInScope;
app.modules.ejbGallery = ejb.gallery.initInScope;

import * as scrollTo from './scripts/scrollTo';
app.modules.scrollTo = scrollTo.initInScope

import * as lazyImg from '../libs/@elements/lazy-img';
app.modules.lazyImg = lazyImg.initInScope;

import * as commerceConnector from './scripts/pds/commerceConnector';
app.modules.commerceConnector = commerceConnector.initInScope;

import * as popover from './../scripts/popover';
app.modules.popover = popover.initInScope;
import * as tooltip from './../scripts/tooltip';
app.modules.tooltip = tooltip.initInScope;

import * as floatingLabel from '../libs/@elements/floating-labels';
app.modules.floatingLabel = floatingLabel.initInScope;

import * as lightbox from '../libs/@elements/lightbox';
app.modules.lightbox = lightbox.initInScope;

import * as parsley from '../libs/@elements/parsley-bootstrap-validation';
app.modules.parsley = parsley.initInScope;
import * as parsleyPassword from './../scripts/parsleyPassword';
app.modules.parsleyPassword = parsleyPassword.initInScope;
import * as parsleyZIP from './../scripts/parsleyZip';
app.modules.parsleyZIP = parsleyZIP.initInScope;

import * as accordionScroll from './../scripts/accordionScroll';
app.modules.accordionScroll = accordionScroll.initInScope;

import * as collapsingCheckbox from './../scripts/collapsingCheckbox';
app.modules.collapsingCheckbox = collapsingCheckbox.initInScope;

import * as langModal from './../scripts/langModal';
app.modules.langModal = langModal.initInScope;

import * as additionalInfo from './../scripts/additionalInfo';
app.modules.additionalInfo = additionalInfo.initInScope;

import * as fullWidthSlider from './scripts/slider/fullWidthSlider';
app.modules.fullWidthSlider = fullWidthSlider.initInScope;

import * as storecheck from './scripts/pds/storecheck';
app.storecheck = storecheck.init;
app.modules.storecheck = storecheck.initInScope;

import * as teaserGrid from './../scripts/teaserGrid';
app.modules.teaserGrid = teaserGrid.initInScope;


import * as productGrid from '../libs/@elements/product-grid';
app.productGrid = productGrid.init;
app.modules.productGrid = productGrid.initInScope;
import * as productGridSlider from './../scripts/productGridSlider';
app.modules.productGridSlider = productGridSlider.initInScope;
// import * as productGridItem from './scripts/productGridItem';
// app.modules.productGridItem = productGridItem.initInScope;
import * as productGridTopFilter from './../scripts/productGridTopFilter';
app.productGridTopFilter = productGridTopFilter.init;
app.modules.productGridTopFilter = productGridTopFilter.initInScope;

import * as linkWithInputVal from './../scripts/linkWithInputVal';
app.modules.linkWithInputVal = linkWithInputVal.initInScope;

import * as pdsLightbox from './scripts/pdsLightbox';
app.modules.pdsLightbox = pdsLightbox.initInScope;

import * as tracking from './../scripts/tracking';
app.modules.tracking = tracking.initInScope;

import * as productcard from './scripts/productcard';
app.modules.productcard = productcard.initInScope;

import * as productReviewAutoTranslate from './../scripts/productReviewAutoTranslate';
app.modules.productReviewAutoTranslate = productReviewAutoTranslate.initInScope;

import * as compareProduct from './../scripts/compareProduct';
app.compareProduct = compareProduct.init;
app.modules.compareProduct = compareProduct.initInScope;

import * as wishlist from './../scripts/wishlist';
app.wishlist = wishlist.init;
app.modules.wishlist = wishlist.initInScope;

import * as ajaxModal from './../scripts/ajaxModal';
app.ajaxModal = ajaxModal.init;
app.modules.ajaxModal = ajaxModal.initInScope;

import * as ajaxAddressModal from './../scripts/ajaxAddressModal';
app.modules.ajaxAddressModal = ajaxAddressModal.initInScope;

import * as addressValidation from './../scripts/addressValidation';
app.modules.addressValidation = addressValidation.initInScope;

import * as friendlyCaptchaV1 from './../scripts/friendlyCaptchaV1';
app.modules.friendlyCaptchaV1 = friendlyCaptchaV1.initInScope;

import * as actionChanger from '../libs/@elements/action-changer';
app.modules.actionChanger = actionChanger.initInScope;

import * as submitOnChange from './../scripts/submitOnChange';
app.modules.submitOnChange = submitOnChange.initInScope;
import * as storeLocator from './../scripts/storeLocator';
app.modules.storeLocator = storeLocator.initInScope;

import * as heroSlider from './scripts/heroSlider';
app.modules.heroSlider = heroSlider.initInScope;

import * as squarelovin from './../scripts/squarelovin';
app.modules.squarelovin = squarelovin.initInScope;

import * as submitForm from './../scripts/submitForm';
app.modules.submitForm = submitForm.initInScope;

import * as submitFormBuilderForm from './../scripts/submitFormBuilderForm';
app.modules.submitFormBuilderForm = submitFormBuilderForm.initInScope;

import * as tabmodule from '../../../shared/contents/tab-module';
tabmodule.init()

app.filterForm = function () {
    import('./../scripts/filter-form/filter-form').then(function (filterForm) {
        app.modules.filterForm = filterForm.initInScope;

        filterForm.init();
        filterForm.initInScope($('body'));
    });
};

app.filterFormMap = function () {
    import('./../scripts/filter-form/filter-form-map').then(function (filterFormMap) {
        app.modules.filterFormMap = filterFormMap.initInScope;
        app.filterFormMap = filterFormMap.init;

        filterFormMap.init();
        filterFormMap.initInScope($('body'));
    });
};

import * as ajaxForm from '../libs/@elements/ajax-form';
app.modules.ajaxForm = ajaxForm.createInitInScope({
    submitOnChange: false,
    addUrlParams: false
});

import * as productSlider from '../libs/@elements/slider';
app.modules.productSlider = productSlider.createInitInScope('.js-productSlider', {
    nextArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-next" aria-label="Next"><i class="icon icon-Pfeil-nachoben rotate-right" style="color: #D2D5D7"></i></button>',
    prevArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-prev" aria-label="Previous"><i class="icon icon-Pfeil-nachoben rotate-left" style="color: #D2D5D7"></i></button>',
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
});

import * as header from './scripts/header';
app.header = header.init;

import * as imageSlider from './scripts/slider/imageSlider';
app.modules.imageSlider = imageSlider.initInScope;

import * as stdSlider from './scripts/slider/stdSlider';
app.modules.stdSlider = stdSlider.initInScope;

import * as pdsSizeChange from './scripts/pds/pdsSizeChange';
app.pdsSizeChange = pdsSizeChange.init;

import * as cartBadge from './../scripts/cartBadge';
app.cartBadge = cartBadge.init;
app.modules.cartBadge = cartBadge.initInScope;

import * as pds from './scripts/pds';
app.modules.pds = pds.initInScope;

import * as overlay from './../scripts/overlay';
app.overlay = overlay.init;
app.modules.overlay = overlay.initInScope;

import * as goToLink from '../libs/@elements/go-to-link';
app.goToLink = goToLink.init;

import * as productGridFilterMobile from './../scripts/productGridFilterMobile';
app.productGridFilterMobile = productGridFilterMobile.init;
app.modules.productGridFilterMobile = productGridFilterMobile.initInScope;

import * as payment from './scripts/payment';
app.modules.payment = payment.init;

import * as paymentPending from './../scripts/paymentPending';
app.modules.paymentPending = paymentPending.initInScope;

import * as paymentPayone from './scripts/payment-payone';
app.modules.paymentPayone = paymentPayone.init;

import * as ajaxShippingMethods from './../scripts/ajaxShippingMethods';
app.modules.ajaxShippingMethods = ajaxShippingMethods.initInScope;

import * as raffle from './../scripts/raffle';
app.raffle = raffle.init;
app.modules.raffle = raffle.initInScope;

import * as loadIcons from '../scripts/loadIcons';
app.modules.loadIcons = loadIcons.init;

import * as countryMismatchPopup from './../scripts/countryMismatchPopup';
app.countryMismatchPopup = countryMismatchPopup.init;

import * as typeahead from '../libs/@elements/typeahead';
import {initializeCookieOverlaysInLightGallery} from "../../../shared/cookie-overlay/cookieOverlaysInLightGallery";

let typeaheadOptions = {
    hint: true,
    highlight: true,
    minLength: 2,
    autocompleteText: 'title'
};
app.modules.typeahead = typeahead.createInitInScope(typeaheadOptions,{
    headerLayout : (data, category) => {
        return(`<div class="typeahead-heading d-flex justify-content-between">
            ${!!data.overviewUrl ? (
            `<a href="${encodeURI(data.overviewUrl)}"
                    class="typeahead-heading__text mb0">`
        ) : ''}
            ${category.title ? (
            `<span class="typeahead-heading__icon text-primary ' + category.icon + '"> ${category.title}</span>`
        ) : ''}
            ${data.overviewUrl ? (
            `</a>`
        ) : ''}
            ${data.overviewUrl ? (
            `<a href="${encodeURI(data.overviewUrl)}"
                class="typeahead-heading__link mb0"><span class="">${category.overviewText || translate('typeahead.showmore')}</span></a>`
        ) : ''}
        </div>`)
    },
    suggestionLayout : (data, category) => {

        const printImage = (imgUrl) => {
            return(
                '<div>' +
                '<img src="' + data.imgurl + '" alt="' + data.title + '"/>' +
                '</div>'
            )
        }

        if(category.name === 'product'){
            return (
                ' <div class="d-flex pb-1 mb-1 border-bottom ml-3">' +
                (data.imgurl ? printImage(data.imgurl) : '') +
                '<div class="ml-3 d-flex justify-content-between flex-column flex-md-row">' +
                '<div class="">' +
                '<p class="mb-0 word-break-word">' + data.title + '</p>' +
                '<small class="word-break-word">' + data.text + '</small>' +
                '</div>' +
                '<div class="text-md-right">' +
                '<strong>' + data.price + '</strong>' +
                '</div>' +
                '</div>' +
                '</div>'
            )
        }else if(category.name === 'magazine'){
            return (
                ' <div class="d-flex pb-1 mb-1 border-bottom">' +
                (data.imgurl ? printImage(data.imgurl) : '') +
                '<div class="d-flex flex-column ml-3">' +
                '<p class="mb-0 word-break-word">' + data.title + '</p>' +
                '<div class="">' + data.teaser + '</div>' +
                '</div>' +
                '</div>'
            )
        }else{
            return (
                ' <div class="typeahead-suggestion">' + data.title + '</div>'
            )
        }
    },
    errorLayout : (data) => {
        return false
    }
});




(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})($);

import * as wcagLinkTargetBlank from '../../../shared/wcag-link-target-blank/wcagLinkTargetBlank'
wcagLinkTargetBlank.init()
