'use strict'
import { preloadNSlide } from '../../../libs/@elements/slider';
import {onEnterViewPort} from "../../../libs/@elements/viewport-utils";

let autoplaySpeed = 8000;

let sliderConfig = {
    draggable: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: false, /*is actually true. Progressbar sets autoplay. This has to be disabled to remove defaultspeed*/
    pauseOnHover: true,
    mobileFirst: true,
    infinite: true,
    waitForAnimate: false,
    adaptiveHeight: true,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                adaptiveHeight: false
            }
        }
    ]
}

export function initInScope($scope) {
    const $sliders = $scope.find('.js-full-width-slider');

    $sliders.each((_,el) => {
        const
            $slider = $(el);

        initSlider($slider, {
            nextArrow: $scope.find('.js-full-width-slider__next'),
            prevArrow: $scope.find('.js-full-width-slider__prev')
        });
    })
}

function initSlider($slider, sliderOptions) {

    sliderOptions = {
        ...sliderConfig,
        ...sliderOptions
    }

    $slider.slick(sliderOptions).on('afterChange', function (event, slick, currentSlide) {
        preloadNSlide(slick, currentSlide + 1, sliderOptions.slidesToShow);
    });


    onEnterViewPort($slider, function ($slider) {
        preloadNSlide($slider.slick('getSlick'), 1, sliderOptions.slidesToShow);
    });

    let $bar,
        isPause,
        tick,
        percentTime;

    let $currentslide = $slider.closest('.js-full-width-slider__container').find('.js-current-slide');

    $bar = $slider.closest('.js-full-width-slider__container').find('.slider-progress .progress');

    $slider.closest('.js-full-width-slider__container').find('.slider-wrapper').on({
        mouseenter: function() {
            isPause = true;
        },
        mouseleave: function() {
            isPause = false;
        }
    });

    function startProgressbar() {
        resetProgressbar();
        percentTime = 0;
        isPause = false;
        tick = setInterval(interval, 10);
    }

    function interval() {
        if(isPause === false) {
            percentTime += 1 / (autoplaySpeed/1000+0.1);
            $bar.css({
                width: percentTime+"%"
            });
            if(percentTime >= 100)
            {
                $slider.slick('slickNext');
                startProgressbar();
            }
        }
    }

    function resetProgressbar() {
        $bar.css({
            width: 0+'%'
        });
        clearTimeout(tick);
    }

    startProgressbar();
    $slider.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
        $currentslide.html(nextSlide+1)
        isPause = true;
        percentTime = 0;
    });
    $slider.on('afterChange', (event, slick, currentSlide, nextSlide) => {
        isPause = false;
    });


}
