'use strict'
import '../../libs/@elements/menu-aim';

let _isMobileNav, _backdropActive, _navOpen, _searchOpen, _cartOpen, _accountOpen;

let $mainNav;
let $searchBtn, $cartBtn, $navItems, $mobileNavBtn, $mobileBackBtn;
let $searchDropdown, $cartDropdown, $navDropdowns, $navItems_sub, $subMenus, $accountBtn, $accountDropdown;
let $navBar;
let $closeBtn;

export const init = () => {
    const $scope = $('body');

    $mainNav        = $scope.find('#main-nav');

    $searchBtn      = $scope.find('.js-header__searchBtn'),
    $cartBtn        = $scope.find('.js-header__cartBtn'),
    $navItems       = $scope.find('.js-header__navItem'),
    $navItems_sub   = $scope.find('.js-header__has-submenu'),
    $mobileNavBtn   = $scope.find('.js-header__opennav'),
    $mobileBackBtn  = $scope.find('.js-header__submenu-heading-back');

    $accountBtn     = $scope.find('.js-header__accountBtn'),
    $accountDropdown = $scope.find('.js-header__accountDropdown'),
    $searchDropdown = $scope.find('.js-header__searchDropdown'),
    $cartDropdown   = $scope.find('.js-header__cartDropdown'),
    $navDropdowns   = $scope.find('.js-header__navDropdown'),
    $navBar         = $scope.find('.js-header__navbar'),
    $closeBtn       = $scope.find('.js-header__dropdown-close'),
    $subMenus       = $scope.find('.js-header__submenu');


    const breakPoint = window.matchMedia('(max-width: 1024px)');

    /** bind mobile / desktop nav switches on matchmedia events **/
    if (document.documentMode || /Edge/.test(navigator.userAgent) || (/iPad|iPhone|iPod/.test(navigator.userAgent) || navigator.userAgent.indexOf("Safari") !== -1 && !window.MSStream)) {
        breakPoint.addListener((e) => resizeHandler(e)) //ie fallback
    }else{
        breakPoint.addEventListener("change", resizeHandler)
    }

    /** init Navigation **/
    if(breakPoint.matches){
        initMobileNav();
    }else{
        initDesktopNav();
    }

    /** Bind NavButton Actions**/
    bindNavButtons();
}

function resizeHandler(e) {
    if(e.matches){
        initMobileNav();
    }else{
        initDesktopNav();
    }
}

function initMobileNav() {
    closeAll();
    $mainNav.menuAim('destroy');
    $navItems.unbind('touchend.desktop');
    $navItems_sub.on('click.mobile', (e) => {
        const $target = $(e.currentTarget);
        e.preventDefault();

        $target.siblings('.js-header__submenu').addClass('show');

    })

    $closeBtn.on('click', (e) => {
        closeAll(e)
    })

    $mobileBackBtn.on('click', (e) => {
        const $target = $(e.currentTarget);

        $target.closest('.js-header__submenu').removeClass('show');
    })

    _isMobileNav = true;
}

function initDesktopNav() {
    closeAll();
    $mainNav = $('#main-nav');
    $navItems_sub.unbind('click.mobile')
    $mainNav.menuAim({
        activate: (li) => {
            if(matchMedia('(max-width: 1024px)').matches){
                return
            }
            openDropdown($(li).find('> a'))
        },
        deactivate: (li) => {
            if(matchMedia('(max-width: 1024px)').matches){
                return
            }
            closeDropdown($(li).find('> a'));
        },
        exitMenu: () => {
            if(matchMedia('(max-width: 1024px)').matches){
                return
            }
            closeNavigation();
        },
        submenuDirection: "below",
        tolerance: 1
    })

    $closeBtn.on('click', (e) => {
        closeAll(e)
    })

    function closeDropdown($target) {
        if($target.hasClass('show')) {
            $target.removeClass('show');
            $target.siblings('.js-header__navDropdown').removeClass('show');
            _navOpen = false;
        }
    }

    function openDropdown($target){
        if(_searchOpen || _cartOpen){
            return;
        }
        if($target.hasClass('show')){
            closeAll();
            return
        }

        if($target.siblings('.js-header__navDropdown').length) {
            showBackdrop();
            $target
                .addClass('show')
                .siblings('.js-header__navDropdown')
                .addClass('show')
                .focus();

            _navOpen = true;
        }else{
            removeBackdrop()
        }
    }

    $navItems.on('touchend.desktop', (e) => {
        const $target = $(e.currentTarget);
        if($target.siblings('.js-header__navDropdown').length){
            openDropdown($target);
            e.preventDefault();
        }else{
            closeAll();
        }
    })

    _isMobileNav = false;
}

function bindNavButtons() {
    $searchBtn.on('click', () => {
        if(_searchOpen){
            closeAll();
        }else{
            openSearch();
        }
    });

    $cartBtn && $cartBtn.on('click', () => {
        if(_cartOpen){
            closeAll();
        }else{
            openCart();
        }
    });
    $mobileNavBtn.on('click',()  => {
        if(_navOpen){
            closeAll()
        }else{
            openNavigation()
        }
    })
    $accountBtn && $accountBtn.on('click', () => {
        if(_accountOpen){
            closeAll();
        }else{
            openAccount();
        }
    });
}

export function openNavigation() {
    if(_navOpen){
        return
    }
    closeAll();
    showBackdrop();
    $navBar.addClass('show');
    $mobileNavBtn.addClass('show');

    _navOpen = true;
}

export function closeNavigation() {
    /** desktop classes **/
    $navItems.removeClass('show');
    $navDropdowns.removeClass('show');

    /** mobile classes **/
    $navBar.removeClass('show')
    $subMenus.removeClass('show');
    $mobileNavBtn.removeClass('show');

    if(!_searchOpen && !_cartOpen){
        removeBackdrop();
    }
    _navOpen = false;
}
export function closeAll() {
    /** general classes **/
    $searchDropdown.removeClass('show');
    $searchBtn.removeClass('show');
    $cartBtn && $cartBtn.removeClass('show');
    $cartDropdown && $cartDropdown.removeClass('show');
    $accountBtn && $accountBtn.removeClass('show');
    $accountDropdown && $accountDropdown.removeClass('show');

    /** desktop classes **/
    $navItems.removeClass('show');
    $navDropdowns.removeClass('show');

    /** mobile classes **/
    $navBar.removeClass('show');
    $subMenus.removeClass('show');
    $mobileNavBtn.removeClass('show');

    removeBackdrop();

    _navOpen = false;
    _searchOpen = false;
    _cartOpen = false;
    _accountOpen = false;
}

function showBackdrop() {
    if(_backdropActive){
        return;
    }
    $('body').append('<div class="header__backdrop fade"></div>')
    setTimeout(() => {
        $('.header__backdrop').addClass('show').on('click', () => {
            closeAll();
        })
    }, 0);
    _backdropActive = true;
}

function removeBackdrop() {
    if(!_backdropActive){
        return
    }
    $('.header__backdrop').remove();
    _backdropActive = false;
}

export function openCart() {
    if(_cartOpen){
       return;
    }
    closeAll();

    $cartBtn && $cartBtn.addClass('show');
    $cartDropdown && $cartDropdown.addClass('show').trigger('cart:shown')
    showBackdrop();
    _cartOpen = true;
}

export function openAccount() {
    if(_accountOpen){
        return;
    }
    closeAll();

    $accountBtn && $accountBtn.addClass('show');
    $accountDropdown && $accountDropdown.addClass('show');
    showBackdrop();

    _accountOpen = true;
}

export function openSearch() {
    if(_searchOpen){
        return;
    }
    closeAll();

    $searchBtn.addClass('show');
    $searchDropdown.addClass('show').trigger('search:shown')
    showBackdrop();

    //focus in search
    $('.js-typeahead__input.tt-input').focus();

    _searchOpen = true;
}
