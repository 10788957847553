"use strict";

export function getLoadingContainer(items = 3) {
    let str = `<span class="loading-overlay loading-overlay--centered loading-overlay--btn js-cart__loading">
                <span class="loading-spinner" role="progressbar" aria-label="Loading...">`;
    for (let i = 0; i < items; i++) {
        str += '<span class="loading-spinner__item loading-spinner__item--' + i + '"></span>'
    }
    str += '</span></span>'

    return str
}

export function removeLoadingContainers() {
    const loadingContainers = document.querySelectorAll('.loading-overlay');
    loadingContainers.forEach((elem)=>{
        elem.remove();
    })
}

