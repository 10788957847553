
"use strict";

export function initInScope($scope) {
    $scope.find('.js-auto-submit').on('change', function (e) {
        e.preventDefault();
        $(this).closest('form').submit();
    });

}
