'use strict'
import { default as throwError } from "../../../libs/@elements/throw-error";

let scriptTag;

export function init() {
    // scriptTag = $('#outtra-script-tag');
    let buttons = document.querySelectorAll('.js-save-size');
    if(typeof buttons !== 'undefined' && buttons != null){
        for (var i = 0, max = buttons.length; i < max; i++) {
            // set up individual event listeners which set the size_preset data attribute on the outtra script tag when clicked
            buttons[i].addEventListener('click', function () {
                let selectedSize = $('.js-selected-size--radio input:checked').attr('data-product-size');
                // outtra_script_node can also be accessed via ID
                let outtra_script_node = document.getElementsByClassName('outtra-script-tag')[0];
                if(typeof outtra_script_node !== 'undefined'){
                    outtra_script_node.setAttribute('data-size_preset', selectedSize)
                }
            });
        }
    }
}

export function initInScope($scope) {
    // const
    //     $sizeRadios = $scope.find('.js-selected-size--radio input');
    //
    // if(!scriptTag || !scriptTag.length){
    //     scriptTag = $('#outtra-script-tag').length ? $('#outtra-script-tag') : throwError('no outtra script found')
    //     return;
    // }
    //
    // $sizeRadios.on('change', (e) => {
    //     scriptTag.attr('data-size_preset', $(e.target).data('product-size').toLowerCase())
    // });
}