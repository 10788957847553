'use strict'

import initModulesInScope from "../../../libs/@elements/init-modules-in-scope";

let productPrice,
    productPriceMobile,
    productAvailability,
    addToCartButton,
    deliveryDateInfo,
    shopActive,
    size,
    $changeSizeChecked;

const changeEvent = new Event('change');

const   $productPriceContainer = $('.js-product-price-container'),
        $productPriceContainerMobile = $('.js-mobile-price'),
        $productAvailabilityContainer = $('.js-product-availability-container'),
        $deliveryDateInfoContainer = $('.js-delivery-date-info'),
        $changeSizeContainer = $('.js-selected-size');

const   $changeSize = $('.js-selected-size--radio input');

export function init() {
    $changeSize.on('change', changeSize);

    $changeSizeChecked = $changeSize.filter(':checked');

    if($changeSizeChecked.length !== 1){
        return;
    }

    $changeSizeChecked.get(0).dispatchEvent(changeEvent);
    saveSize();
}

function changeSize(e) {
    if($(e.target).val()){
        if(!Boolean($(e.target).data('size-available'))) {
            return;
        }
        productPrice = $(e.target).data('price');
        productPriceMobile = $(e.target).data('price');
        productAvailability = $(e.target).data('availability');
        addToCartButton = $(e.target).data('cart-button');
        size = $(e.target).data('size');
        deliveryDateInfo = $(e.target).data('delivery-date');
        shopActive = $(e.target).data('shop-active');

        $('.js-selected-size__text').text(`(${$(e.target).data('product-size')})`);
        saveSize();
    }
}

function saveSize() {
    $productPriceContainer.html(productPrice);
    $productPriceContainerMobile.html(productPriceMobile);
    $productAvailabilityContainer.html(productAvailability);
    $('.js-add-to-cart-btn-container').html(addToCartButton);
    $('.js-parsley__form-errors').html('');
    $changeSizeContainer.trigger('size:changed');

    if (shopActive) {
        $deliveryDateInfoContainer.html(deliveryDateInfo);
    }

    $('.js-pds__color-select__link').each(function (idx, element) {
        var href = $(element).attr('href');
        var indexOf = href.indexOf('?');
        href = href.substring(0, indexOf < 0 ? href.length : indexOf);
        $(element).attr('href',href + '?size=' + size);
    });
    initModulesInScope($('.js-pds__product'));
}
