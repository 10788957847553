'use strict'
import { init as initVideo } from './../../scripts/jsVideo';
import {preloadNSlide} from "../../libs/@elements/slider";
import {onEnterViewPort} from "../../libs/@elements/viewport-utils";
import {pauseVideo, playVideo, seekTo} from "../../../../shared/video-control/videoControl";

let autoplaySpeed = 8000;

const
    sliderOptions = {
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        dots: true,
        autoplay: false, /*is actually true. Progressbar sets autoplay. This has to be disabled to remove defaultspeed*/
        pauseOnHover: true,
        mobileFirst: true,
        infinite: true,
        waitForAnimate: false,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    adaptiveHeight: false
                }
            }
        ]

    }

export function initInScope($scope) {
    const
        $sliders    = $scope.find('.js-hero-slider')

    $sliders.each((_,el) => {
        if($(el).find('.js-hero-slider__slide').length > 1){
            initHeroSlider($(el))
        }else{
            $(el).find('.js-hero-slider__arrows').removeClass('d-md-block')
            const $video = $(el).find('.js-hero-slider__video');

            if($video.length){
                /* Video.js seems to have problems with autoplay on Video.js in FF -> Video.js is not used here */
                $video.attr('autoplay', 'autoplay'); /* For FF */
                playVideo($video[0])

                $(el).find('.js-hero-slider__video').addClass('vjs-has-started');
            }
        }
    })

}

/**
 * initHeroSlider initializes a single hero player.
 * it handles youtube videos and video-js videos
 * @param $scope
 */
function initHeroSlider($scope) {
    let
        videos      = [],
        ytvideos = []
    const
        $slider         = $scope.find('.js-hero-slider__slider'),
        $slides         = $scope.find('.js-hero-slider__slide'),
        $videoSlides    = [],
        $ytVideoSlides  = [],
        $navbar         = $scope.find('.js-hero-slider__navbar')

    let ytVideoSelector = window.matchMedia('(max-width: 767px)').matches ? '.js-hero-slider__ytvideo iframe' : '.js-hero-slider__ytvideo--desktop iframe';

    const init = () => {
        initSlider($slider, $navbar,
            Object.assign(sliderOptions, {
                // nextArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-next" aria-label="Next"><i class="icon icon-arrow-right icon-4x text-white"></i></button>',
                // prevArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-prev" aria-label="Previous"><i class="icon icon-arrow-left icon-4x text-white"></i></button>',
                nextArrow: $scope.find('.js-hero-slider__next'),
                prevArrow: $scope.find('.js-hero-slider__prev'),
                appendDots: $scope.find('.js-hero-slider__dots')
            }));

        $slides.each((_, el) => {

            const $slide = $(el);
            if($slide.find('.js-hero-slider__video').length){
                $videoSlides[_] = $slide;
            }

            if ($slide.find(ytVideoSelector).length) {
                $ytVideoSlides[_] = $slide
            }
        })

        if($videoSlides.length){
            initVideos();
        }
        if($ytVideoSlides.length){
            initYoutubeVideos();
        }

        $slider.on('beforeChange', function (e, slick, currentSlide, nextSlide) {
            let $nextSlide = $(slick.$slides.get(nextSlide));
            let $currentSlide = $(slick.$slides.get(currentSlide));

            if($nextSlide.find('.js-hero-slider__slide').data('hero-slider-color')){
                $navbar.css('background-color', $nextSlide.find('.js-hero-slider__slide').data('hero-slider-color'))
            }

            /**
             * pause and play video-js instances
             */
            try {
            if($currentSlide.find('.js-hero-slider__video').length){
                    pauseVideo(videos[currentSlide])
            }

            if($nextSlide.find('.js-hero-slider__video').length){
                    seekTo(videos[nextSlide], 0)
                    playVideo(videos[nextSlide])
                }
            } catch (e) {
            }

            /**
             * pause and play youtube instance
             */
            try {
                if ($currentSlide.find(ytVideoSelector).length) {
                $ytVideoSlides[currentSlide].hide();
                    pauseVideo(ytvideos[currentSlide]);
            }
                if ($nextSlide.find(ytVideoSelector).length) {
                $ytVideoSlides[nextSlide].fadeIn();
                    seekTo(ytvideos[nextSlide], 0)
                    playVideo(ytvideos[nextSlide])
                }
            } catch (e) {
            }
        })
    }

    const initVideos = () => {
        import('video.js').then(function (videojs){
            $videoSlides.forEach((el, _) => {
                videos[_] = initVideo($(el).find('.js-hero-slider__video'), videojs);
                if(_ === 0){
                    videos[_].ready(() => {
                        window.requestAnimationFrame(() => {
                            videos[_].play();
                            $(el).find('.js-hero-slider__video').addClass('vjs-has-started');
                        })
                    });
                }
            })
        })
    }

    /**
     * Initializes Youtube videos after loading the youtube-player api abstraction
     */
    const initYoutubeVideos = () => {
            $ytVideoSlides.forEach((el, _)=> {
            const $videoContainer = $(el).find(ytVideoSelector)
            ytvideos[_] = $videoContainer[0];
        })
    }

    init();
}

function initSlider($slider, $navbar, sliderOptions) {

    $slider.on('init', function () {
        const $currentSlide = $(this).find('.slick-slide.slick-current .js-hero-slider__slide');
        if($currentSlide.data('hero-slider-color')){
            $navbar.css('background-color', $currentSlide.data('hero-slider-color'))
        }
    }).slick(sliderOptions).on('afterChange', function (event, slick, currentSlide) {
        preloadNSlide(slick, currentSlide + 1, sliderOptions.slidesToShow);
    })

    onEnterViewPort($slider, function ($slider) {
        preloadNSlide($slider.slick('getSlick'), 1, sliderOptions.slidesToShow);
    });

    let $bar,
        isPause,
        tick,
        percentTime;

    let $currentslide = $slider.closest('.js-hero-slider').find('.js-current-slide');
    $slider.closest('.js-hero-slider').find('.js-num-of-slides').html($slider.closest('.js-hero-slider').find('.js-hero-slider__slide').length)

    $bar = $slider.closest('.js-hero-slider').find('.slider-progress .progress');

    $slider.closest('.js-hero-slider').find('.slider-wrapper').on({
        mouseenter: function() {
            isPause = true;
        },
        mouseleave: function() {
            isPause = false;
        }
    });

    function startProgressbar() {
        resetProgressbar();
        percentTime = 0;
        isPause = false;
        tick = setInterval(interval, 10);
    }

    function interval() {
        if(isPause === false) {
            percentTime += 1 / (autoplaySpeed/1000+0.1);
            $bar.css({
                width: percentTime+"%"
            });
            if (percentTime >= 100) {
                $slider.slick('slickNext');
                startProgressbar();
            }
        }
    }

    function resetProgressbar() {
        $bar.css({
            width: 0+'%'
        });
        clearTimeout(tick);
    }

    startProgressbar();
    $slider.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
        $currentslide.html(nextSlide+1)
        isPause = true;
        percentTime = 0;
        // resetProgressbar();

    });
    $slider.on('afterChange', (event, slick, currentSlide, nextSlide) => {
        // $currentslide.html(currentSlide+1)
        isPause = false;
        // startProgressbar();
        // resetProgressbar();
    });
}

