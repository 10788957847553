"use strict";
import {onFind} from '../../libs/@elements/init-modules-in-scope';
import {removeLoadingContainers} from "./loading-spinner";

import {on, addClass, removeAttribute, setAttribute, find} from "../../libs/@elements/dom-utils";

export function init () {
    window.onload = function(){

        const submitBtns = document.querySelectorAll('button[type="submit"]');

        window.addEventListener('pagehide', (event) => {
            submitBtns.forEach((elem)=>{
                elem.disabled = false;
                elem.classList.remove('disabled');
                removeLoadingContainers();
            })
        });

        let errorHolder = find('#error-holder');
        let heidelPay = find('.js-heidelpay');

        if(heidelPay) {
            let heidelpayInstance = new heidelpay(_config.paymentAccessKey, {locale: _config.paymentLocale});

            let Card = heidelpayInstance.Card();
            // Rendering input fields
            Card.create('number', {
                containerId: 'card-element-id-number',
                onlyIframe: false
            });
            Card.create('expiry', {
                containerId: 'card-element-id-expiry',
                onlyIframe: false
            });
            Card.create('cvc', {
                containerId: 'card-element-id-cvc',
                onlyIframe: false
            });

            let ccForm = document.getElementById('cc-form');
            let paymentForm = document.getElementById('payment-form');
            // General event handling
            let buttonDisabled = {};
            let submitButton = document.getElementById('submit-button');
            submitButton.disabled = true;


            Card.addEventListener('change', function(e) {
                if (e.success) {
                    buttonDisabled[e.type] = true;
                    submitButton.disabled = false;
                    errorHolder.innerHTML = '';
                } else if (e.error) {
                    buttonDisabled[e.type] = false;
                    submitButton.disabled = true;
                    errorHolder.innerHTML = e.error;
                } else {
                    buttonDisabled[e.type] = false;
                    submitButton.disabled = true;
                    errorHolder.innerHTML = '';
                }
                submitButton.disabled = !(buttonDisabled.number && buttonDisabled.expiry && buttonDisabled.cvc);

            });



            let Sepa = heidelpayInstance.SepaDirectDebit();

            Sepa.create('sepa-direct-debit', {
                containerId: 'sepa-IBAN'
            });

            let sepaForm = document.getElementById('payment-form-sepa');
            if(sepaForm) {
                let submitButtonSepa = document.getElementById('submit-button-sepa');
                submitButtonSepa.disabled = true;

                Sepa.addEventListener('change', function(e) {
                    if (e.success) {
                        submitButtonSepa.disabled = false;
                        errorHolder.innerHTML = '';
                    } else {
                        submitButtonSepa.disabled = true;
                        errorHolder.innerHTML = e.error;
                    }
                });
            }

            let successHandler = function(data) {
                data.method = data.method ? data.method : 'card';
                let paymentMethod = document.getElementsByClassName('js-payment-method-hidden');
                let paymentId = document.getElementsByClassName('js-payment-id-hidden');

                for (let i = 0; i < paymentMethod.length; i++) {
                    paymentMethod[i].value = data.method;
                    paymentId[i].value = data.id;
                }

                if(data.method == 'card') {
                    let paymentCC = find('.js-payment-credit-card-hidden');
                    paymentCC.value = data.brand;
                    paymentForm.submit();
                }

                if(data.method == 'sepa-direct-debit') {
                    paymentForm.submit();
                }
            };

            let errorHandler = function(error) {
                errorHolder.innerHTML = error.message;
            };

            if(ccForm) {
                ccForm.addEventListener('submit', function (event) {
                    event.preventDefault();
                    Card.createResource()
                        .then(successHandler)
                        .catch(errorHandler)
                });
            }

            if(sepaForm) {
                sepaForm.addEventListener('submit', function (event) {
                    event.preventDefault();
                    Sepa.createResource()
                        .then(successHandler)
                        .catch(errorHandler)
                });
            }


            let paypalBtn = find('.js-payment-method-btn-paypal');
            if(paypalBtn) {
                paypalBtn.addEventListener('click', function(event) {
                    event.preventDefault();

                    var Paypal = heidelpayInstance.Paypal();

                    Paypal.createResource()
                        .then(successHandler)
                        .catch(errorHandler)
                });
            }

            let sofortBtn = find('.js-payment-method-btn-sofort');
            if(sofortBtn) {
                sofortBtn.addEventListener('click', function(event) {
                    event.preventDefault();

                    var Sofort = heidelpayInstance.Sofort();

                    Sofort.createResource()
                        .then(successHandler)
                        .catch(errorHandler)
                });
            }

            let invoiceBtn = find('.js-payment-method-btn-invoice-guaranteed');
            if(invoiceBtn) {
                invoiceBtn.addEventListener('click', function(event) {
                    event.preventDefault();

                    var Invoice = heidelpayInstance.InvoiceGuaranteed();

                    Invoice.createResource()
                        .then(successHandler)
                        .catch(errorHandler)
                });
            }
        }
    };


    onFind('.js-payment-method-btn', function (btn) {
        on('click', function (e) {
            e.preventDefault();
            let paymentMethodBtn = document.getElementsByClassName('js-payment-method-btn');
            for (let i = 0; i < paymentMethodBtn.length; i++) {
                paymentMethodBtn[i].classList.remove('payment-methods__item--selected');
            }
            addClass('payment-methods__item--selected', this);

            let submitPaymentBtn = document.getElementsByClassName('js-submit-payment__btn');
            for (let i = 0; i < submitPaymentBtn.length; i++) {
                removeAttribute('hidden', submitPaymentBtn[i]);
                removeAttribute('disabled', submitPaymentBtn[i]);
            }

            let paymentCollapse = document.getElementsByClassName('js-payment-collapse');
            for (let i = 0; i < paymentCollapse.length; i++) {
                setAttribute('hidden', true, paymentCollapse[i]);
            }
        }, btn)
    });


    onFind('.js-payment-method-btn--collapse', function (btn) {
        on('click', function (e) {
            e.preventDefault();
            let submitPaymentBtn = document.getElementsByClassName('js-submit-payment__btn');
            for (let i = 0; i < submitPaymentBtn.length; i++) {
                setAttribute('hidden', true, submitPaymentBtn[i]);
            }

            let currentCollapse = this.getAttribute('data-collapse-item');
            let currentCollapseItem = document.getElementById(currentCollapse);
            removeAttribute('hidden',currentCollapseItem);

        }, btn)
    });


    /*if (_config.paymentShowDonationOverlay) {
        let $modal = $('#payment-donation-modal');
        let donationValueSelected = false;
        let $donationForm = $('.js-payment__donation-form');

        $scope.find('.js-submit-payment__btn').on('click', function (evt) {
            if (!donationValueSelected) {
                evt.preventDefault();
                evt.stopImmediatePropagation();

                $modal.on('donation-value-selected', function () {
                    $('.js-payment__form').submit();
                });

                $modal.modal({backdrop: 'static', keyboard: false}).modal('show');
            }
        });

        $scope.find('.js-payment__payment-form-submit').on('click', function (evt) {
            let $submitButton = $(this);
            if (!donationValueSelected) {
                evt.preventDefault();
                evt.stopImmediatePropagation();

                $modal.on('donation-value-selected', function () {
                    $submitButton.click();
                });

                $modal.modal({backdrop: 'static', keyboard: false}).modal('show');
            }
        });

        $donationForm.on('submit', function (evt) {
            evt.preventDefault();

            if ($(this).parsley().isValid()) {
                $('.js-payment__form-donate-value').val($('.js-payment__donation-option input:checked').val());
                donationValueSelected = true;

                $(this).trigger('donation-value-selected');

                $modal.modal('hide');
            }
        });
    }*/


    /*if(window.matchMedia('(max-width: 767px)').matches) {
        $('#cc-form-collapse').on('shown.bs.collapse', function () {
            $('html, body').stop().animate({
                scrollTop: $('#cc-form-collapse').offset().top-85
            }, '300');
        });
        $('#invoicePayment').on('shown.bs.collapse', function () {
            $('html, body').stop().animate({
                scrollTop: $('#invoicePayment').offset().top-85
            }, '300');
        });
        $('#direct-debit-form-collapse').on('shown.bs.collapse', function () {
            $('html, body').stop().animate({
                scrollTop: $('#direct-debit-form-collapse').offset().top-85
            }, '300');
        });
    }*/
}