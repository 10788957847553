'use strict';
import * as stdSlider from '../../../libs/@elements/slider';


export function initInScope($scope){

    let options;
    let $sliders = $scope.find('.js-stdSlider');

    $sliders.each(function (index, slider) {
        $(window).on('resize', function() {
            $(slider).hasClass('slick-initialized')
            if( $(window).width() > 767 &&  !$(slider).hasClass('slick-initialized')) {
                options = getOptions();
                stdSlider.createInitInScope(slider, options )($scope);
            }
        });

        options = getOptions();
        stdSlider.createInitInScope(slider, options )($scope);

    });

    let $repaintElements = $('.js-stdSlider ').find('.js-repaint');
    for(let i = 0; i < $repaintElements.length; i++){
        $repaintElements[i].style.display = 'none';
        $repaintElements[i].offsetHeight;
        $repaintElements[i].style.display = '';

        setTimeout(function () {
            $repaintElements[i].style.display = 'none';
            $repaintElements[i].offsetHeight;
            $repaintElements[i].style.display = '';
        }, 5000)
    }
}

function getOptions() {
    return {
        nextArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-next px-0" aria-label="Next"><i class="icon icon-Pfeil-nachoben icon-rotate-90 slider-btn"></i></button>',
        prevArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-prev px-0" aria-label="Previous"><i class="icon icon-Pfeil-nachoben icon-rotate-270 slider-btn"></i></button>',
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
    }
}