'use strict'
import {getPrefixedDataSet} from '../../libs/@elements/data-set-utils'
import initModulesInScope from "../../libs/@elements/init-modules-in-scope";

// remaining sizes label
const minTotalSizes = 5;
const minNotAvailableSizes = 4;

const visibilityInvisibile = 'invisible';
const visibilityNone = 'd-none';

export function initInScope($scope) {
    const $productCards = $scope.find('.js-productcard');

    $productCards.each((_, el) => {
        const
            $card = $(el),
            $variants = $card.find('.js-productcard__variant');

        updateAvailability($card);
        $variants.each((index, el) => {
            updateAvailability($(el), true);
        });

        $variants.on('hover click', (e) => changeCard(e, $card));
    })
    const changeCard = function (e, $card) {
        e.preventDefault();
        e.stopImmediatePropagation();

        const
            $variant = $(e.target),
            data = getPrefixedDataSet('productcard', $variant),
            $img = $card.find('.js-productcard__img'),
            $price = $card.find('.js-productcard__price'),
            $label = $card.find('.js-productcard__label'),
            $availability = $card.closest('.js-productcard').find('.js-productGridItem__availability-text'),
            $remainingSizes = $card.closest('.js-productcard').find('.js-productGridItem__remaining-sizes');


        const newImage = $(data.img).css({
            visibility: 'hidden'
        })
        $img.append(newImage)
        newImage.prop('onload', function (e) {
            this.style.visibility = 'visible';
            setTimeout(() => {
                $(this).prev().remove();
            }, 1000)
        });


        /* active state */
        $card.find('.js-productcard__variant').removeClass('active');
        $variant.addClass('active');


        $card.find('.js-go-to-link__link').attr('href', data.url)

        $price.html(data.price)
        $label.html(data.label)

        $card.find('.js-wishlist__btn').data('wishlist-id', data.colourId);
        $card.find('.js-compare-list__btn').data('compare-list-id', data.colourId);

        if (data.availability) {
            $availability.addClass(visibilityInvisibile);
        } else {
            $availability.removeClass(visibilityInvisibile);
        }

        // TODO:58487 fix FE
        // if(data.remainingSizes){
        //     $remainingSizes.removeClass(visibilityNone);
        // } else {
        //     $remainingSizes.addClass(visibilityNone);
        // }

        initModulesInScope($card);
    }
}

export function updateAvailability($card, productVariant = false) {
    const
        $notAvailableSizesContainer = $card.find('.js-productGridItem__not-available-sizes:first');

    const totalAvailableSizes = $notAvailableSizesContainer.data('total-available-sizes');
    let totalNotAvailableSizes = 0;

    const $notAvailableSizes = $notAvailableSizesContainer.find('.js-productGridItem__not-available-sizes-size');
    $notAvailableSizes.each((index, el) => {
        if (parseInt($(el).data('not-available-size-id')) > 0) {
            totalNotAvailableSizes++;
        }
    })

    if (totalNotAvailableSizes === totalAvailableSizes) {
        if (productVariant) {
            $card.attr('data-productcard-availability', false);
        } else {
            const $availabilityText = $card.find('.js-productGridItem__availability-text:first');
            $availabilityText.removeClass(visibilityInvisibile);
        }
    } else if (totalAvailableSizes > minTotalSizes && (totalAvailableSizes - totalNotAvailableSizes) <= minNotAvailableSizes) {
        if (productVariant) {
            $card.attr('data-productcard-remaining-sizes', true);
        } else {
            // TODO:58487 fix FE
            // const $remainingSizes = $card.find('.js-productGridItem__remaining-sizes:first');
            // $remainingSizes.removeClass(visibilityNone);
        }
    }

}