'use strict'

import {onFind} from '../../libs/@elements/init-modules-in-scope';
import {addClass, on, removeAttribute, setAttribute} from "../../libs/@elements/dom-utils";
import { translate } from "../../../../shared/translations/translations"

class PayoneCheckout {
    constructor(config) {

        this.paymentMethod = 'card' // default payment method

        this._apiCredentials = config.apiCredentials
        this._apiMode = config.apiMode ?? 'test'
        this._style = config.style
        this._supportedCardTypes = config.supportedCardTypes ?? ['V', 'M']

        // Payone's hosted JS, in order to invoke handler for successful credit card check, expects string name of a global function.
        // That's why we need to make the handler global. In this case, name of the handler is 'storeCreditCardCheckResponse'
        window.storeCreditCardCheckResponse = this._storeCreditCardCheckResponse.bind(this)
    }

    mount(selector) {
        this._container = document.querySelector(selector)
        if (this._container === null) {
            console.log(`Couldn't mount PayOne to selector '${selector}'. Provide valid selector for the checkout container`)
            return;
        }

        // Render supported card types
        const cardTypesLine = this._container.querySelector('#checkoutCardTypesLine')
        cardTypesLine.innerHTML = this._supportedCardTypes.reduce((acc, type) => (
            acc + `<img class="payone-checkout__card ${type === 'V' ? 'payone-checkout__card-visa' : ''}" data-card-type="${type}" src="https://cdn.pay1.de/cc/${type.toLowerCase()}/s/transparent.png" alt="${type} card icon">`
        ), '')

        // Use Payone's Client API hostted iFrames to receive inputted card's data
        this._checkout = new Payone.ClientApi.HostedIFrames(
            {
                fields: {
                    cardpan: {
                        selector: 'cardNumber',
                        type: 'text',
                        maxlength: '19'
                    },
                    cardexpiremonth: {
                        selector: 'cardExpiryMonth',
                        type: 'text',
                        maxlength: '2',
                    },
                    cardexpireyear: {
                        selector: 'cardExpiryYear',
                        type: 'text',
                        maxlength: '2'
                    },
                    cardcvc2: {
                        selector: 'cvc',
                        type: 'password',
                        maxlength: '4',
                        length: {'V': 3, 'M': 3}
                    },
                },

                defaultStyle: {
                    input: this._style?.default ?? 'border: 1px solid lightgray; border-radius: 5px; font-size: 18px; box-sizing: border-box; padding: 8px 10px; width: 99%; height: 99%',
                    inputFocus: this._style?.focused ?? 'border: 1px solid #0969da; border-radius: 5px; font-size: 18px; box-sizing: border-box; padding: 8px 10px; width: 99%; height: 99%; outline: none;',
                    iframe: {},
                },

                autoCardtypeDetection: {
                    supportedCardtypes: this._supportedCardTypes,
                    callback: (detectedCardType) => {
                        document.querySelectorAll('[data-card-type]').forEach((cardTypeIcon) => {
                            cardTypeIcon.classList.remove('payone-js-checkout__card--highlighted')
                        })

                        const detectedCardTypeIcon = document.querySelector(`[data-card-type=${detectedCardType}]`)
                        detectedCardTypeIcon.classList.add('payone-js-checkout__card--highlighted')
                    }
                },
            },
            {
                'mid': this._apiCredentials.merchantId,
                'portalid': this._apiCredentials.portalId,
                'aid': this._apiCredentials.subAccountId,
                'hash': this._apiCredentials.hash,
                'mode': this._apiMode,
                'responsetype': 'JSON',
                'encoding': 'UTF-8',
                'request': 'creditcardcheck',
                'storecarddata': 'yes'
            }
        )

        let paymentForm = document.getElementById('payment-form');
        const paymentMethodTag = paymentForm.querySelector('.js-payment-method-hidden')
        let submitPaymentBtn = document.getElementsByClassName('js-submit-payment__btn');
        if (paymentMethodTag.value.length === 0) {
            for (let i = 0; i < submitPaymentBtn.length; i++) {
                setAttribute('hidden', submitPaymentBtn[i]);
            }
        }

        onFind('.js-submit-payment__btn', function (btn) {
            on('click', function (e) {
                e.preventDefault();
                let paymentForm = document.getElementById('payment-form');
                paymentForm.submit();
            }, btn)
        });

        onFind('.js-payment-method-btn', function (btn) {
            on('click', function (e) {
                e.preventDefault();
                let paymentMethodBtn = document.getElementsByClassName('js-payment-method-btn');
                for (let i = 0; i < paymentMethodBtn.length; i++) {
                    paymentMethodBtn[i].classList.remove('payment-methods__item--selected');
                }
                addClass('payment-methods__item--selected', this);

                let paymentForm = document.getElementById('payment-form');
                const paymentMethodTag = paymentForm.querySelector('.js-payment-method-hidden')
                paymentMethodTag.value = this.dataset.paymentMethod;

                if (paymentMethodTag.value !== 'card' && paymentMethodTag.value !== 'paylater-invoice') {
                    let submitPaymentBtn = document.getElementsByClassName('js-submit-payment__btn');
                    for (let i = 0; i < submitPaymentBtn.length; i++) {
                        removeAttribute('hidden', submitPaymentBtn[i]);
                    }
                }

                let paymentCollapse = document.getElementsByClassName('js-payment-collapse');
                for (let i = 0; i < paymentCollapse.length; i++) {
                    setAttribute('hidden', true, paymentCollapse[i]);
                }

                let currentCollapse = this.getAttribute('data-collapse-item');
                let currentCollapseItem = document.getElementById(currentCollapse);
                removeAttribute('hidden',currentCollapseItem);
            }, btn)
        });

        const ccForm = this._container.querySelector('#card-form-payone')
        const errorTag = ccForm.querySelector('#error-holder');
        ccForm.addEventListener('submit', (event) => {
            event.preventDefault();
            errorTag.innerHTML = '';
            this.pay().then( result => {
                if (result.status === 'error') {
                    if (result._payone_creditCardCheckResponse && result._payone_creditCardCheckResponse.errorcode) {
                        errorTag.innerHTML = translate("payone.errorMessage." + result._payone_creditCardCheckResponse.errorcode);
                    } else {
                        errorTag.innerHTML = result.message;
                    }
                }
            });
        });

        return this
    }

    async pay() {
        switch (this.paymentMethod) {
            case 'card':
                return new Promise(async (resolve) => {
                    if (!this._checkout.isComplete()) {
                        return resolve({
                            status: 'error',
                            type: 'not_all_fields_filled_properly',
                            message: translate("payone.errorMessage.not_all_fields_filled_properly")
                        })
                    }

                    const lastCreditCardCheckResponse = this._creditCardCheckResponse ?? {}
                    this._checkout.creditCardCheck('storeCreditCardCheckResponse')
                    // Stop execution until we get credit card check response from Payone's hosted JS
                    await new Promise((resolve) => {
                        setInterval(() => {
                            if (this._creditCardCheckResponse === undefined) return;
                            // If we still haven't received updated credit card check response, do nothing and continue waiting
                            if (objectsAreEqual(this._creditCardCheckResponse, lastCreditCardCheckResponse)) return

                            resolve()
                        }, 50)
                    })

                    switch (this._creditCardCheckResponse.status) {
                        case 'INVALID':
                        case 'ERROR':
                            return resolve({
                                status: 'error',
                                type: 'credit_card_check_error',
                                message: translate("payone.errorMessage.credit_card_check_error"),
                                _payone_creditCardCheckResponse: this._creditCardCheckResponse
                            })
                        default:
                            return resolve({
                                status: 'error',
                                type: 'credit_card_check_error',
                                message: translate("payone.errorMessage.credit_card_check_error"),
                                _payone_creditCardCheckResponse: this._creditCardCheckResponse
                            })

                        // Actually make request to user-specified endpoint that makes true payment request
                        case 'VALID':
                            const paymentMethod = 'card'
                            const pseudoCardPan = this._creditCardCheckResponse.pseudocardpan

                            const truncatedCardPAN = this._creditCardCheckResponse.truncatedcardpan
                            const cardExpireDate = this._creditCardCheckResponse.cardexpiredate
                            const cardType = this._creditCardCheckResponse.cardtype
                            const _lastPaymentMethodSpecificData = { truncatedCardPAN, cardExpireDate, cardType }
                            localStorage.setItem('lastPaymentMethodSpecificData', JSON.stringify(_lastPaymentMethodSpecificData))

                            let paymentForm = document.getElementById('payment-form');
                            const paymentMethodTag = paymentForm.querySelector('.js-payment-method-hidden')
                            const ccTag = paymentForm.querySelector('.js-payment-credit-card-hidden')
                            const pseudoTag = paymentForm.querySelector('.js-payment-pseudo-credit-card-hidden')

                            paymentMethodTag.value = paymentMethod;
                            ccTag.value = cardType;
                            pseudoTag.value = pseudoCardPan;
                            paymentForm.submit();

                            return resolve({
                                status: 'success',
                            })
                    }
                })

            default:
                console.error(`Undefined payment method - ${this.paymentMethod}`)
                return resolve({
                    status: 'error',
                    type: 'invalid payment method',
                    message: 'Invaliud payment method'
                })
        }
    }

    _storeCreditCardCheckResponse(creditCardCheckResponse) {
        this._creditCardCheckResponse = creditCardCheckResponse
    }
}

function objectsAreEqual(objOne, objTwo) {
    const objOneSize = Object.keys(objOne).length;
    const objTwoSize = Object.keys(objTwo).length;
    if (objOneSize !== objTwoSize) return false;

    let objectsAreEqual = true;
    for (const prop in objOne) {
        if (objOne[prop] !== objTwo[prop]) {
            objectsAreEqual = false;

            break;
        }
    }

    return objectsAreEqual;
}
export function init() {

    const payoneApiCredentials = {
        merchantId: _config.payoneMerchantId,
        portalId: _config.payonePortalId,
        subAccountId: _config.payoneSubaccountId,
        hash: _config.payoneHash
    }

    window.onload = function() {
        new PayoneCheckout({
            apiCredentials: payoneApiCredentials,
            apiMode: _config.payoneMode,
            supportedCardTypes: _config.supportedCardTypes
        }).mount('#card-form-collapse-payone');


        const invoiceForm = document.getElementById('paylater-form-payone')

        if(invoiceForm) {
            const customerBirthdayInput = document.getElementById('customerBirthdate')
            invoiceForm.addEventListener('submit', function(e) {
                e.preventDefault()
                let birthdate = document.getElementsByClassName('js-payment-birthdate-hidden');
                let paymentForm = document.getElementById('payment-form');
                for (let i = 0; i < birthdate.length; i++) {
                    birthdate[i].value = customerBirthdayInput.value;
                }
                paymentForm.submit();
            });
        }
    };
}
