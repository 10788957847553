'use strict'

import { addLoadingContainer, removeLoadingContainer } from "../../scripts/loading";
import { showNotification } from "../../libs/@elements/alert-notification"
import throwError from "../../libs/@elements/throw-error"
import { translate } from "../../../../shared/translations/translations"
import initModulesInScope from "../../libs/@elements/init-modules-in-scope";
import fetch from "../../libs/@elements/fetch"
import 'url-search-params-polyfill'; // Edge Polyfill
import formDataEntries from 'form-data-entries';
import { handleTrackingOnPromise } from "../../scripts/tracking";
import { closeAll } from "./header"

let cartOpen = false;
let $container, $form;
let pendingRequest = null;

export function init() {
    const
        $trigger = $('body').find('.js-header__cartBtn');

    //this function is for triggering the submit on opening and also closing the cart when clicking outside..
    // its not beautiful but it works :D
    $trigger.on('click', (e) => {
        if($trigger.hasClass('show')){
            openCart(e);
        }
    })
}

export function openCart(event = null) {
    submitForm(null, null, true, () => {
        setCartDropdownHeight($container, 75);
    });
    cartOpen = true;
    event && event.stopPropagation();
    setCartDropdownHeight($container, 75);
}

export function initInScope($scope) {
    if($scope.find('.js-cart-dropdown__container').length){
        $container  = $scope.find('.js-cart-dropdown__container');
    }
    if($scope.hasClass('js-cart-dropdown__form') || $scope.find('.js-cart-dropdown__form').length){
        $form = $scope.hasClass('js-cart-dropdown__form') ? $scope : $scope.find('.js-cart-dropdown__form');
        pendingRequest = null;
        initCartDropdown($form);
    }
}

function preventSubmits($form) {

    let $inputs = $form.find('input');

    $inputs.on('keydown', (e) => {
        if(event.keyCode == 13){
            /** Customer wanted to change it back to submit on enter on 23.10.19 - TaskID: #536632 **/
            e.preventDefault();
            submitForm(null)
        }
    })

}

function submitForm(removeId = null, removeToken = null, nodata = false, callback = null) {
    if(pendingRequest){
        pendingRequest.abort();
        pendingRequest = null;
    }
    addLoadingContainer($container, [true, false, true])

    let url = $form.data('action-refresh') || throwError(translate('cart.no.action.url'))

    let SearchParams = null;

    if(removeId){
        let formData = new FormData($form[0]);
        SearchParams = new URLSearchParams({
            ...formData,
            removeId : removeId
        });
    } else if(removeToken){
        let formData = new FormData($form[0]);
        SearchParams = new URLSearchParams({
            ...formData,
            removeToken : removeToken.data,
            tokenType : removeToken.type
        });
    } else if(!nodata){
        SearchParams = new URLSearchParams(formDataEntries($form[0]));
    }
    pendingRequest = fetch(url, {
        body: SearchParams,
        cache: "no-store"
    });
    $('#ajax-modal-container').html('');
    pendingRequest.then((res) => {
        return res.clone().json()
    })
        .then((res) => {
            if(res.success){
                $form = $(res.html)
                initModulesInScope($form)
                $container.html($form);
                if(res.count !== 'undefined'){
                    notifyBadge(res.count);
                }
                if(res.modal){
                    const $modal = $(res.modal)
                    $('#ajax-modal-container').append($modal);
                    $modal.modal('show');
                }

                if(callback && typeof(callback) === 'function' ) {
                    callback();
                }
            }
        })
        .finally(() => {
            removeLoadingContainer($container)
        })
        .catch((error) => {
            if(error.name == 'AbortError'){
                return;
            }else {
                console.error(error)
            }
        })
    showNotification(pendingRequest);

    // Track changes to cart
    handleTrackingOnPromise(pendingRequest);
}

function initCartDropdown($scope) {
    const
        $inputs     = $scope.find('.js-cart-dropdown__autosubmit-change'),
        $buttons    = $scope.find('.js-cart-dropdown__autosubmit-click'),
        $removeBtn  = $scope.find('.js-cart-dropdown__remove'),
        $closeBtn   = $scope.find('.js-cart-dropdown__close'),
        $removeTokenBtn  = $scope.find('.js-cart-dropdown__remove-token');

    $closeBtn.on('click', () => {
        closeAll();
    })

    preventSubmits($form);

    $inputs.on('change', () => submitForm());
    $buttons.on('click', () => submitForm());
    $removeBtn.on('click', function (e) {
        e.preventDefault();
        const removeId = $(this).data('id');
        submitForm(removeId);
    });
    $removeTokenBtn.on('click', function (e) {
        e.preventDefault();
        const removeToken = {
            data: $(this).data('token'),
            type: $(this).data('type')
        };
        submitForm(null, removeToken);
    })
}

function notifyBadge(count) {
    $('body').find('.js-cart-badge').trigger('cart:update', {count: count})
}

function setCartDropdownHeight(element, offset = 0) {
    if(!cartOpen) {
        return;
    }

    let availableArea = window.innerHeight - offset;
    $form.css('max-height', availableArea);
}
