"use strict";
import 'slick-carousel';
import { preloadNSlide } from '../../../libs/@elements/slider';
import { onEnterViewPort } from "../../../libs/@elements/viewport-utils";

export function initInScope($scope){
    const
        $sliders = $scope.find('.js-imageSlider'),
        bigOptions = {
            infinite: true,
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-next" aria-label="Next"><i class="icon icon-Pfeil-nachoben icon-rotate-90 imageSlider-icon text-white"></i></button>',
            prevArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-prev" aria-label="Previous"><i class="icon icon-Pfeil-nachoben icon-rotate-270 imageSlider-icon text-white"></i></button>',
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        dots: true,
                        nextArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-next" aria-label="Next"><i class="icon icon-Pfeil-nachoben icon-rotate-90 imageSlider-icon text-white"></i></button>',
                        prevArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-prev" aria-label="Previous"><i class="icon icon-Pfeil-nachoben icon-rotate-270 imageSlider-icon text-white"></i></button>',
                    }
                }
            ]
        },
        smallOptions = {
            infinite: true,
            dots: false,
            slidesToShow: 5,
            slidesToScroll: 1,
            centerMode: false,
            centerPadding: 0,
            focusOnSelect: true,
            nextArrow: '',
            prevArrow: '',
        }

    if($sliders.length < 0){
        return;
    }

    $sliders.each((_, el) => {
        const
            $bigSlider = $(el).find('.js-imageSlider__big'),
            $smallSlider = $(el).find('.js-imageSlider__small');

        initSlider($bigSlider, {...bigOptions,asNavFor: $smallSlider});
        initSlider($smallSlider, {...smallOptions, asNavFor: $bigSlider});
    })

}

function initSlider($slider, sliderOptions) {
    $slider.slick(sliderOptions).on('afterChange', function (event, slick, currentSlide) {
        preloadNSlide(slick, currentSlide + 1, sliderOptions.slidesToShow);
    });

    onEnterViewPort($slider, function ($slider) {
        preloadNSlide($slider.slick('getSlick'), 1, sliderOptions.slidesToShow);
    });
}