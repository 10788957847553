'use strict'
import { preloadNSlide } from '../../libs/@elements/slider';
import { onEnterViewPort } from "../../libs/@elements/viewport-utils";
import { throttle } from 'throttle-debounce';

const
    hightlightOptions = {
        waitForAnimate: false
    },
    mainSliderOptions = {
        slidesToShow: 1,
        adaptiveHeight: false,
        infinite: false,
        prevArrow: '<button type="button" class="btn pds-slider-main__btns btn-icon btn-unstyled slick-arrow slick-prev" aria-label="Previous"><i style="color: #BEBEBE" class="icon icon-gonso-arrow-left icon-2x"></i></button>',
        nextArrow: '<button type="button" class="btn pds-slider-main__btns btn-icon btn-unstyled slick-arrow slick-next" aria-label="Next"><i style="color: #BEBEBE" class="icon icon-gonso-arrow-right icon-2x"></i></button>',
        dots: false,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    adaptiveHeight: true,
                    dots: true
                }
            }
        ]
    },
    thumbSliderOptions = {
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: '<button type="button" class="btn btn-icon btn-unstyled bg-white--transparent slick-arrow slick-prev pds-slider-small__btns" aria-label="Previous"><i style="transform: rotate(90deg); color: #606060;" class="icon icon-gonso-arrow-left"></i></button>',
        nextArrow: '<button type="button" class="btn btn-icon btn-unstyled bg-white--transparent slick-arrow slick-next pds-slider-small__btns" aria-label="Next"><i style="transform: rotate(90deg); color: #606060;" class="icon icon-gonso-arrow-right"></i></button>',
        dots: false,
        vertical: true,
        verticalSwiping: true,
        focusOnSelect: true,
        draggable: false,
        infinite: false,
    },
    addOnSliderOptions = {
        slidesToShow: 4,
        adaptiveHeight: true,
        dots: false,
        infinite: false,
        nextArrow: '<button type="button" class="btn btn-icon slick-arrow slick-next pds__addon-slider__btn" aria-label="Next"><i class="icon icon-gonso-arrow-right icon-2x"></i></button>',
        prevArrow: '<button type="button" class="btn btn-icon slick-arrow slick-prev pds__addon-slider__btn" aria-label="Previous"><i class="icon icon-gonso-arrow-left icon-2x"></i></button>',
        responsive: [
            {
                breakpoint: 1451,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]

    }

export function initInScope($scope) {
    const
        $highlightSliders   = $scope.find('.js-pds__highlight-slide'),
        $productOverview    = $scope.find('.js-pds__product'),
        $application        = $scope.find('.js-pds__application'),
        $addOnSliders       = $scope.find('.js-pds__addon-slider')

    $highlightSliders.each((_,el) => {
        initHighlightSlider($(el));
    })
    if($productOverview.length !== 0){
        initProductOverview($productOverview);
    }
    if($application.length !== 0){
        initApplicationCollapse($application)
    }
    if($addOnSliders.length !== 0){
        $addOnSliders.each((_, el) => {
            initAddonSlider($(el));
        })
    }
}


function initAddonSlider($addOnSliders) {
    $addOnSliders.slick({
        ...addOnSliderOptions,
    }).on('afterChange', function (event, slick, currentSlide) {
        preloadNSlide(slick, currentSlide + 1, addOnSliderOptions.slidesToShow);
    });

    onEnterViewPort($addOnSliders, function ($slider) {
        preloadNSlide($slider.slick('getSlick'), 1, addOnSliderOptions.slidesToShow);
    });

}

function initApplicationCollapse($application) {
    const
        mobileBreak     = window.matchMedia('(max-width: 767px)'),
        $collapses      = $application.find('.pds__application-collapse'),
        $collapseBtn    = $application.find('.js-pds__application-heading')

    $collapseBtn.on('click', (e) => {
        if(matchMedia('(min-width: 768px)').matches){
            e.stopPropagation();
        }
    })
    /**
     * manuel polyfill. addListener is deprecated but in ie and edge addEventListerner is not yet supported
     */
    if (document.documentMode || /Edge/.test(navigator.userAgent) || (/iPad|iPhone|iPod/.test(navigator.userAgent)  || navigator.userAgent.indexOf("Safari") !== -1 && !window.MSStream)){
        mobileBreak.addListener((e) => {
            if(e.matches){
                $collapses.collapse({
                    toggle: false
                })
            }
        })
    }else{
        mobileBreak.addEventListener('change', (e) => {
            if(e.matches){
                $collapses.collapse({
                    toggle: false
                })
            }
        })
    }
    $(mobileBreak).trigger('change')
}

function setTextOffset($productOverview) {
    const
        $descr                  = $('.js-pds__descr'),
        $descrContainerDesktop  = $('.js-pds__descr-desktop'),
        $descrContainerMobile   = $('.js-pds__descr-mobile')

    if($(window).outerWidth() <= 767){
        $productOverview.find('.js-pds__product-slider').css({
            "transform": `translateY(-${0}px)`,
            "margin-bottom" : `-${0}px`
        })
        $descrContainerMobile.append($descr)
        $descrContainerDesktop.empty();
        return;
    }
    const
        textHeight  = $productOverview.find('.js-pds__product-name').height(),
        $slider     = $productOverview.find('.js-pds__product-slider')
    $slider.css({
        "transform" : `translateY(-${textHeight}px)`,
        "margin-bottom" : `-${textHeight}px`
    })
    $descrContainerDesktop.append($descr);
    $descrContainerMobile.empty()
}

function initProductOverview($productOverview) {
    setTextOffset($productOverview)
    /* setTimeout fixes initialisation bug in firefox due to css not loaded */
    setTimeout(() => {
        initProductSliders($productOverview)
    }, 1000)

    const textOffsetFnc = throttle(150, () => setTextOffset($productOverview))
    $(window).on('load resize orientationchange', textOffsetFnc);
}
function initProductSliders($productOverview) {
    const
        $mainSlider     = $productOverview.find('.js-pds__product-slider-main'),
        $thumbSlider    = $productOverview.find('.js-pds__product-slider-thumb')

    $mainSlider.slick({
        ...mainSliderOptions,
        asNavFor: $thumbSlider
    }).on('afterChange', function (event, slick, currentSlide) {
        preloadNSlide(slick, currentSlide + 1, mainSliderOptions.slidesToShow);
    });

    onEnterViewPort($mainSlider, function ($slider) {
        preloadNSlide($slider.slick('getSlick'), 1, mainSliderOptions.slidesToShow);
    });

    $thumbSlider.slick({
        ...thumbSliderOptions,
        asNavFor: $mainSlider,
    }).on('afterChange', function (event, slick, currentSlide) {
        preloadNSlide(slick, currentSlide + 1, thumbSliderOptions.slidesToShow);
    });

    onEnterViewPort($thumbSlider, function ($slider) {
        preloadNSlide($slider.slick('getSlick'), 1, thumbSliderOptions.slidesToShow);
    });
}


function initHighlightSlider($scope) {
    const
        $slider         = $scope.find('.js-pds__highlight-slider'),
        $sliderCounter  = $scope.find('.js-pds__highlight-counter'),
        $navBtns        = $scope.find('.js-pds__highlight-slider-btn')

    $slider.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
        //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
        let i = (currentSlide ? currentSlide : 0) + 1;
        $sliderCounter.html('<span class="text-primary">' + i + '</span> / ' + slick.slideCount);
    })
    $slider.on('init', function () {
        $navBtns.eq(0).addClass('pds__highlight-slider-btn--active');
    })
    $slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        $navBtns.removeClass('pds__highlight-slider-btn--active')
        $navBtns.eq(nextSlide).addClass('pds__highlight-slider-btn--active')
    })

    $slider.slick({
        ...hightlightOptions,
        prevArrow: $scope.find('.slick-prev'),
        nextArrow: $scope.find('.slick-next')
    }).on('afterChange', function (event, slick, currentSlide) {
        preloadNSlide(slick, currentSlide + 1, hightlightOptions.slidesToShow);
    });

    onEnterViewPort($slider, function ($slider) {
        preloadNSlide($slider.slick('getSlick'), 1, hightlightOptions.slidesToShow);
    });

    $navBtns.on('click', function () {
        $slider.slick('slickGoTo', $(this).data('go-to-slide'))
    })
}