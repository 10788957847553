'use strict'
import {default as initMap, setMarker, addMarker, removeAllMarker, loadGoogleMapsAPI} from "../../../libs/@elements/google-map";
import fetch from '../../../libs/@elements/fetch';
import {getLoadingContainer, removeLoadingContainer} from '../../../scripts/loading'
import { showNotification } from "../../../libs/@elements/alert-notification";
import asyncAppend from '../../../libs/@elements/async-append';
import { translate } from '../../../../../shared/translations/translations'
import shuffle from 'shuffle-array';
import { Pagination } from 'paginatedjs/build/paginate-js.min'
import pagination from 'pagination';

const token = 'c4971d6826b7ec99dc452e569a3380905fc881f3';
const _language = _config.lang || 'de';
const _ean = _config.ean;  //_config.product.EAN
const _country = _config.locale.split('_')[1] || 'DE';
const _apiUrl = new URL('/REST/2.0', 'https://api-retailer.global.commerce-connector.com');

export async function initInScope($scope) {
    const
        $commerceStoreBtn = $scope.find('.js-commerceConnector__button'),
        $changeSizeRadios = $scope.find('.js-selected-size--radio'),
        $changeSizeContainer = $scope.find('.js-selected-size');
        $initialSizeRadio = $changeSizeRadios.find('input').filter(":checked");

    // In case we have a preselected size on init, update the _config
    if ($initialSizeRadio.length) {
        _config.ean = $initialSizeRadio.data('ean');
    }
    if(_config.ean){
        initStoreBtn($scope)
    }

    $commerceStoreBtn.on('click', (e) => {
        if($commerceStoreBtn.hasClass('disabled')){
            e.stopImmediatePropagation();
        }
    })

    $changeSizeContainer.on('size:changed', () => {
        _config.ean = $changeSizeRadios.find('input').filter(":checked").data('ean');
        if(_config.ean){
            initStoreBtn($scope)
        }
    })
}

export async function initStoreBtn($scope) {
    const
        $commerceStoreBtn   = $scope.find('.js-commerceConnector__button')

    if($commerceStoreBtn.length){
        const stores = await storesAvailable();
        if(stores.online){
            $commerceStoreBtn.removeClass('disabled')
            $commerceStoreBtn.show();
            initOnlineStoresSearch($scope);
        }else{
            $commerceStoreBtn.removeClass('disabled')
            printNoStores($scope);
        }
    }
}

function printNoStores($scope) {
    const
        $modal      = $scope.find('.modal--commerceConnectorOnline'),
        $container  = $modal.find('.js-commerceConnector__online-result')

    $container.empty();

    $container.html('<p>' + translate('commerce.connector.no-stores') + '</p>')
}


export async function storesAvailable() {
    let url = new URL(_apiUrl + '/ArticleReach');
    url.searchParams.set('token', token);
    url.searchParams.set('Country', _country);
    url.searchParams.set('F_Ean', _config.ean);
    url.searchParams.set('Language', _language.toLowerCase());

    let request = fetch(url, {method: 'GET'});
    return request.then((res) => {
        if (!res.ok) {
            throw Error(res.statusText)
        }
        return res.clone().json();
    }).then((res) => {
        if(res.Channels){
            return {
                "offline" : !!parseInt(res.Channels.LocalStores),
                "online": !!parseInt(res.Channels.OnlineShops)
            }
        }
    }).catch(error => {
        console.error('CommerceConnector request failed:', error)
        return {}
    })
}

export function initOnlineStoresSearch($scope){
    const
        $modal = $scope.find('.modal--commerceConnectorOnline');

    let url = new URL(_apiUrl + '/OnlineShop');
    url.searchParams.set('token', token);
    url.searchParams.set('O_ResultFields', "Name,ContactInfo,OfferInfo,Logos,ShippingDefinitions");
    url.searchParams.set('F_Ean', _config.ean);
    url.searchParams.set('Country', _country);

    const templateString = document.getElementById('commerceConnectorOnlineStoresTemplate').innerHTML;
    let pageItems;
    const itemsPerPage = 4;


    function initStores(stores) {
        pageItems = new Pagination(stores, itemsPerPage)
        return renderStores()
    }

    function renderStores(page = 1){
        let resultHtml = '';

        pageItems.goToPage(page)
        const stores = pageItems.getPaginated(true)

        stores.forEach(function (store) {
            resultHtml += printStore(store)
        })

        resultHtml += renderPagination();

        return resultHtml;
    }

    function renderPagination() {
        let paginator = new pagination.TemplatePaginator({
            prelink:'#',
            current: pageItems.pageNumber,
            rowsPerPage: itemsPerPage,
            totalResult: pageItems.count(),
            slashSeparator: false,
            template: function(result) {
                var i, len, prelink;
                var html = '<div><ul class="pagination d-flex justify-content-center">';
                if(result.pageCount < 2) {
                    html += '</ul></div>';
                    return html;
                }
                prelink = this.preparePreLink(result.prelink);
                if(result.previous) {
                    html += '<li class="page-item"><a class="page-link js-commerceConnector__pagination-goTo" data-page-id="' + result.previous + '" href="' + prelink + result.previous + '">' + translate('commerce.connector.page.prev') + '</a></li>';
                }
                if(result.range.length) {
                    for( i = 0, len = result.range.length; i < len; i++) {
                        if(result.range[i] === result.current) {
                            html += '<li class="active page-item"><span class="page-link">' + result.range[i] + '</span></li>';
                        } else {
                            html += '<li class="page-item"><a class="page-link js-commerceConnector__pagination-goTo" data-page-id="' + result.range[i] + '" href="' + prelink + result.range[i] + '">' + result.range[i] + '</a></li>';
                        }
                    }
                }
                if(result.next) {
                    html += '<li class="page-item"><a class="page-link js-commerceConnector__pagination-goTo" data-page-id="' + result.next + '" href="' + prelink + result.next + '" class="paginator-next">' + translate('commerce.connector.page.next')  + '</a></li>';
                }
                html += '</ul></div>';
                return html;
            }
        });
        return paginator.render();
    }

    function bindPaginationFunctions() {
        const $items      = $scope.find('.js-commerceConnector__pagination-goTo');

        $items.on('click', (e) => {
            e.preventDefault();
            const page = $(e.delegateTarget).data('page-id');
            bindPaginationFunctions($modal.find('.js-commerceConnector__online-result').empty().append(renderStores(page)));
        })

    }

    function printStore(store){
        return templateString
            .replace(/{name}/g, store.Name)
            .replace(/{link}/g, store.OfferInfo.Offers[0].Deeplink)
            .replace(/{img}/g, store.Logos[0].LogoMedium)
            .replace(/{delivery}/g, parseInt(store.OfferInfo.Offers[0].AvailabilityInfo[0].DeliveryTime) > 0 ? store.OfferInfo.Offers[0].AvailabilityInfo[0].DeliveryTime : translate('commerce.connector.no.delivery.time.available'))
            .replace(/{trackingAction}/g, store.Name)
    }

    asyncAppend({
        $target: $modal.find('.js-commerceConnector__online-result'),
        $loading: $(getLoadingContainer()),
        $notification: $modal.find('.js-commerceConnector_online-notifications')
    }, fetch(url).then((res) => {
        return res.clone().json()
    }).then((res) => {
        try {
            if (res.getResult[0].ResultGroups[0].OnlineShops) {
                const stores = res.getResult[0].ResultGroups[0].OnlineShops;
                res.html = initStores(stores);
            }
        }catch(err) {
            res.html = translate('commerce.connector.no.stores');
            console.warn('error when accessing stores');
        }
        return res;
    })).then((params) => {
        bindPaginationFunctions();
    })
}
