'use strict'

export function initInScope($scope) {
    const
        $closeMap       = $scope.find('.js-store-locator__closeMap'),
        $map            = $scope.find('.js-store-locator__map'),
        $openMap        = $scope.find('.js-show-dealer-map');

    $openMap.on('click', function () {
        openMap();
    })

    $closeMap.on('click', function () {
        closeMap();
    })

    function openMap() {
        $map.addClass('is-open');
        $('body').addClass('modal-open');
    }

    function closeMap() {
        if($map.hasClass('is-open')){
            $map.removeClass('is-open');
            $('body').removeClass('modal-open');
        }
    }

}