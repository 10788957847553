'use strict'
import throwError from "../../libs/@elements/throw-error"
import { showNotification } from "../../libs/@elements/alert-notification"
import { addLoadingContainer, removeLoadingContainer, addSuccessContainer} from '../../scripts/loading';
import fetch from "../../libs/@elements/fetch"
import { handleTrackingOnPromise } from '../../scripts/tracking'
import { isParsleyForm, isValid } from "../../libs/@elements/parsley-bootstrap-validation";
import formDataEntries from 'form-data-entries';
import { openCart } from './header'
import { openCart as loadCart } from './cart-dropdown'

let addToCartUrl = null;

export function init() {
    if(window['_addToCart']){
        addToCartUrl = window['_addToCart'].url || throwError("missing window['_addToCart'].url in window['_addToCart']")
    }else {
        throwError("missing window['_addToCart'] ")
    }
}

export function initInScope($scope) {
    if(!addToCartUrl){
        return
    }
    const
        $cartBtns = $scope.find('.js-add-to-cart');

    $cartBtns.each((_,el) => {
        initCartBtn($(el))
    })
}

function initCartBtn($button) {
    const
        articleId = $button.data('product-id');

    let pendingRequest = null, $notificationContainer;

    function addToCart(e) {
        if(pendingRequest){
            pendingRequest.abort();
            pendingRequest = null;
        }

        let payload = [];

        if($button.closest('form').length){
            e.preventDefault();
            const $form = $button.closest('form');
            $notificationContainer = $form.find('.js-add-to-cart__notifications')
            let selectedSizeElement = $('.js-selected-size__text');
            let selectedSizeText = selectedSizeElement.html();
            let selectedSizeErrorMsg = selectedSizeElement.data('error-msg');

            if (isParsleyForm($form) && !isValid($form) || selectedSizeElement && !selectedSizeText) {
                e.preventDefault();
                e.stopImmediatePropagation();
                if(!selectedSizeText){
                    $('.js-parsley__form-errors').html('<p class="text-danger pl-2 pt-2 mb-0">' + selectedSizeErrorMsg + '</p>');
                }
                return;
            }

            payload = formDataEntries($form[0])
        }
        if(articleId){
            payload.push(['articleId', articleId])
        }

        addLoadingContainer($button,[],false)

        pendingRequest = fetch(addToCartUrl, {
            method: 'POST',
            body: new URLSearchParams(payload)
        });
        pendingRequest.then((res) => {
            return res.clone().json()
        })
            .then((res) => {
                if(res.success){
                    notifyBadge(res.count);
                    addSuccessContainer($button);
                    loadCart();
                    openCart();
                }
            })
            .finally(() => {
                removeLoadingContainer($button)
            })
            .catch((error) => {
                if(error.name == 'AbortError'){
                    return;
                }else {
                    console.error(error)
                }
            })

        if($notificationContainer !== undefined && $notificationContainer.length){
            showNotification(pendingRequest, {
                $container: $notificationContainer,
                styleModifier: 'test-style-right-now'
            });
        }else{
            showNotification(pendingRequest);
        }

        // Track add to cart
        handleTrackingOnPromise(pendingRequest);
    }

    $button.on('click', (e) => addToCart(e))
}

function notifyBadge(count) {
    $('body').find('.js-cart-badge').trigger('cart:update', {count: count});
}
